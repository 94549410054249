.App {
  width: 100%;
  margin: 0 auto;
  padding: 0px;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header-wrapper {
  padding: 20px;
  background-color: #efefef;
}

.App-header {
  /*background-color: #efefefef;*/
  /*min-height: 100vh;*/
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-header h1 {
  padding-left: 20px;
}

.main-wrapper {
  width: 960px;
  margin: 0 auto;
  padding: 20px;
}

body {
  background-color: #efefefef;
}

body::before {
  content: "";
  background: url(static/images/Inkedjessica-biel_LI.jpg) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;;
  opacity: .07;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.App-link {
  color: #61dafb;
  padding-top: 5vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

nav ul {
  list-style: none;
  display: flex;
  /*background-color: white;*/
  margin-bottom: 20px;
}

nav ul li {
  padding: 20px;
}

nav ul li a {
  color: black;
  text-decoration: none;
}

.current {
  border-bottom: 4px solid black;
}

p {
  margin-bottom: 15px;
}


.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  max-height: 60vmin;
  overflow: hidden;
}